<template>
  <div>
    <v-btn class="ma-0 mb-5 ml-5" color="primary" @click="synchEventsRacketcamp()"> Synch Events.Racketcamp </v-btn>
    <add-room-modal title="+ Создать бронирование" @add-reservation="AddReservation"></add-room-modal>
    <v-btn class="ma-0 mb-5 ml-5" color="primary" @click="addBonusTransaction()"> Начислить бонусы </v-btn>
    <h1>{{ $store.getters.event.header }}</h1>
    <v-card id="account-setting-card">
      <!-- tabs -->
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.id" @click="getTabsData(tab)">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item :touch="false">
          <v-card>
            <v-data-table
              mobile-breakpoint="100"
              :headers="headers"
              :items="reservationList"
              class="table-rounded elevation-1"
              :items-per-page="-1"
              hide-default-footer
              :loading="loading"
              loading-text="Загрузка данных"
            >
              <template #[`item.id`]="{ item }">
                <div class="d-flex flex-column">
                  <router-link :to="'/events/' + item.event_id + '/' + item.uuid">
                    <span class="d-block font-weight-semibold">{{ item.id }}</span>
                    <span>{{ item.date }}</span>
                  </router-link>
                </div>
              </template>

              <template #[`item.user`]="{ item }">
                <div :class="{ reservation_confirm: item.confirm }">
                  {{ item.user.name }}
                </div>
              </template>

              <template #[`item.room`]="{ item }">
                <p v-for="(dpt, index) in item.room" :key="index" class="mt-2">
                  {{ dpt.name }} - №{{ dpt.room_number }}
                  <v-tooltip right>
                    <span>Страховка</span>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-if="dpt.insurance" v-bind="attrs" color="success" v-on="on">
                        {{ icons.mdiShieldAccount }}
                      </v-icon>
                    </template>
                  </v-tooltip>
                  <v-tooltip right>
                    <span>Промокод {{ item.promocode }}</span>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-if="item.promocode" v-bind="attrs" color="success" v-on="on">
                        {{ icons.mdiSale }}
                      </v-icon>
                    </template>
                  </v-tooltip>
                </p>
              </template>

              <!-- status -->
              <template #[`item.pay_status`]="{ item }">
                <v-chip
                  v-if="item.pay_price == item.pay_sum && item.pay_price != 0"
                  small
                  :color="statusColor[status[1]]"
                  class="font-weight-medium"
                >
                  {{ status[1] }}
                </v-chip>
                <span v-else-if="item.pay_price == 0">
                  {{ status[5] }}
                </span>
                <v-chip v-else-if="item.pay_sum <= 0" small :color="statusColor[status[3]]" class="font-weight-medium">
                  {{ status[3] }}
                </v-chip>
                <v-chip
                  v-else-if="item.pay_price > item.pay_sum"
                  small
                  :color="statusColor[status[2]]"
                  class="font-weight-medium"
                >
                  {{ status[2] }}
                </v-chip>

                <v-chip
                  v-else-if="item.pay_sum > item.pay_price"
                  small
                  :color="statusColor[status[4]]"
                  class="font-weight-medium"
                >
                  {{ status[4] }}
                </v-chip>
              </template>

              <template #[`item.payment_method`]="{ item }">
                <div>
                  <span v-if="item.warning" :color="red">⚠️</span>
                  {{ payment_method_type[item.payment_method] }}
                </div>
              </template>

              <template slot="body.append">
                <tr>
                  <th>Totals</th>
                  <th>{{ reservationList.length }}</th>
                  <th></th>
                  <th>{{ sumField('pay_price') }}</th>
                  <th>{{ sumField('pay_sum') }}</th>
                  <th>{{ sumField('pay_debt') }}</th>
                  <th>{{ sumField('bonus_pay') }}</th>
                  <th></th>
                  <th></th>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>

        <v-tab-item :touch="false">
          <v-card>
            <v-progress-linear :active="loader" :indeterminate="loader"></v-progress-linear>
            <v-simple-table mobile-breakpoint="100" height="80vh" fixed-header>
              <thead>
                <tr>
                  <th width="450" class="text-left p-0 m-0">Номер</th>
                  <th class="text-left">
                    <div class="d-flex">
                      <div v-for="d in event_rooms_list.event_date" :key="d" class="range_object">
                        {{ d }}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in event_rooms_list.rooms" :key="item.id">
                  <td>
                    <v-card-title class="mx-0 px-0" style="font-size: 13px">
                      <v-icon v-if="item.status" small class="mr-2" color="success">
                        {{ icons.mdiContactlessPaymentCircle }}
                      </v-icon>
                      {{ item.name }} №{{ item.room_number }}
                      <v-icon class="ml-2" small dark color="primary" @click="getRoomData(item.id)">
                        {{ icons.mdiLeadPencil }}
                      </v-icon>
                    </v-card-title>
                    <v-card-subtitle class="mx-0 px-0 mb-0 pb-0" style="font-size: 10px">
                      {{ item.package }}
                    </v-card-subtitle>
                    <v-card-subtitle class="mx-0 px-0 mb-0" style="font-size: 10px">
                      <div>Тип бронирования: {{ item.order_room_type_name }}</div>
                      <div v-if="item.order_room_type == 1">Пол номера: {{ item.gender_room_type_name }}</div>
                    </v-card-subtitle>
                  </td>
                  <td :colspan="event_rooms_list.event_date.length + 1">
                    <div v-for="reservation in item.reservation" :key="reservation.id" class="reservation_wrap">
                      <div class="reservation_block">
                        <div
                          class="reservation d-flex justify-space-between align-center"
                          :style="'width:' + reservation.width + '%; left:' + reservation.left + '%'"
                        >
                          <div>
                            {{ reservation.people_in_room.length }}
                            {{ reservation.name }}
                          </div>
                          <div>
                            <v-btn icon small @click="reservation.show_people = !reservation.show_people">
                              <v-icon small color="white">
                                {{ icons.mdiEye }}
                              </v-icon>
                            </v-btn>
                            <v-btn icon small @click="getRoomListByEventIdAndRoomType(reservation)">
                              <v-icon small color="white">
                                {{ icons.mdiSwapHorizontal }}
                              </v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <div v-show="reservation.show_people" class="reservation_people_wrap">
                        <div v-for="p in reservation.people_in_room" :key="p.id" class="reservation_wrap">
                          <div class="reservation_block_item">
                            <div class="reservation" :style="'width:' + p.width + '%; left:' + p.left + '%'">
                              {{ p.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-tab-item>

        <v-tab-item :touch="false">
          <v-card>
            <v-progress-linear :active="loader" :indeterminate="loader"></v-progress-linear>
            <v-simple-table mobile-breakpoint="100" height="75vh" fixed-header>
              <thead>
                <tr>
                  <th class="text-left">Id</th>
                  <th class="text-left">Участник</th>
                  <th class="text-left">Телефон</th>
                  <th class="text-left">Даты</th>
                  <th class="text-left">Взрослый/Ребенок</th>
                  <th class="text-left">Возраст ребенка</th>
                  <th class="text-left">Вид спорта</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(people, idx) in event_people_list" :key="people.id">
                  <td>{{ idx + 1 }}</td>
                  <td>{{ people.name }}</td>
                  <td>{{ people.phone }}</td>
                  <td>{{ people.start }} - {{ people.end }}</td>
                  <td>
                    <span v-if="people.age_type == 2">Ребенок</span>
                    <span v-else>Взрослый</span>
                  </td>
                  <td>
                    <span v-if="people.age_type == 2">{{ people.child_age }}</span>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <v-select
                      v-if="people.type == 1"
                      v-model="people.sport"
                      :items="people.sport_list"
                      item-text="name"
                      item-value="value"
                      label="Вид спорта"
                      class="mt-5"
                      @change="changeEventPeopleSportType(people)"
                    ></v-select>
                    <span v-else> сопровождающий </span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-tab-item>

        <v-tab-item :touch="false">
          <v-card>
            <v-progress-linear :active="loader" :indeterminate="loader"></v-progress-linear>
            <v-simple-table mobile-breakpoint="100" height="75vh" fixed-header>
              <thead>
                <tr>
                  <th class="text-left">Тренер</th>
                  <th class="text-left">Статус ответа</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="offer in offers_list" :key="offer.id">
                  <td>
                    <router-link :to="'/events/' + $route.params.id + '/offer/' + offer.uuid">
                      <span>{{ offer.trainer_name }}</span>
                    </router-link>
                  </td>
                  <td>
                    <template v-if="offer.answered">
                      <template v-if="offer.accepted"> Согласовано </template>
                      <template v-else> Отказ </template>
                    </template>
                    <template v-else> Ответ не получен </template>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <v-btn small class="ma-0 mb-5 ml-5 mt-10" color="primary" @click="new_offer_popup = true">
                  + Добавить оффер
                </v-btn>
              </tfoot>
            </v-simple-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog v-model="new_reservation_popup" width="100%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Выберете номер </v-card-title>

        <v-simple-table>
          <template v-if="free_event_rooms">
            <thead>
              <tr>
                <th class="text-left">Id</th>
                <th>Номер</th>
                <th>Тип номера</th>
                <th>Пол номера</th>
                <th>Свободных мест</th>
                <th>Действие</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="suit in free_event_rooms" :key="suit.id">
                <td>{{ suit.id }}</td>
                <td>{{ suit.name }} - {{ suit.room_number }}</td>
                <td>{{ suit.order_room_type_name }}</td>
                <td>{{ suit.gender_room_type_name }}</td>
                <td>{{ suit.free_places }}</td>
                <td>
                  <v-btn x-small color="success" @click="changeRoom(suit.id)"> Перселить </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>

    <v-dialog v-model="new_offer_popup" persistent width="100%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Создать новый оффер </v-card-title>
        <v-card-text>
          <template>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field v-model="trainer_name" :rules="trainer_nameRules" label="Имя" required></v-text-field>

              <v-text-field v-model="trainer_email" :rules="trainer_emailRules" label="E-mail" required></v-text-field>

              <v-textarea
                v-model="agreed_functionality"
                outlined
                :rules="agreed_functionalityRules"
                label="Функционал"
                required
              ></v-textarea>

              <v-textarea
                v-model="salary_description"
                outlined
                :rules="salary_descriptionRules"
                label="Условия оплаты"
                required
              ></v-textarea>
            </v-form>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-title> Оффер </v-card-title>
        <v-card-text v-if="event_data">
          <template>
            <p>
              Здравствуйте, {{ trainer_name }}!<br />
              Вам направлено предложение принять участие на мероприятии в качестве тренера / члена команды
              организаторов.
            </p>

            <p>
              Ниже представлены подробные данные и условия сотрудничества. <br />
              Название мероприятия: <b>{{ event_data.name }}</b> <br />
              Даты: <b>{{ event_data.start }} - {{ event_data.end }}</b> <br />
              Расчетное количество рабочих дней: <b>{{ event_data.days }}</b> <br />
            </p>
            <p>
              Согласованный функционал: <br />
              <span style="white-space: pre-line">{{ agreed_functionality }}</span>
            </p>
            <p>
              Условия оплаты: <br />
              <span style="white-space: pre-line">{{ salary_description }}</span>
            </p>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :disabled="!valid" color="success" class="mr-4" @click="addOffer"> добавить оффер </v-btn>
          <v-btn color="error" class="mr-4" @click="reset"> отменить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiAccount,
  mdiAccountGroup,
  mdiAccountOutline,
  mdiBedKing,
  mdiCart,
  mdiContactlessPaymentCircle,
  mdiCurrencyRub,
  mdiDotsVertical,
  mdiEye,
  mdiFileDocument,
  mdiInformationOutline,
  mdiLeadPencil,
  mdiLockOpenOutline,
  mdiSale,
  mdiShieldAccount,
  mdiSquareEditOutline,
  mdiSwapHorizontal,
} from '@mdi/js'

import Api from '@/api/api'
import MyLoader from '@/components/MyLoader.vue'
import AddRoomModal from '@/views/events/AddRoomModal.vue'
import { ref } from 'vue'
import { Editor } from 'vuetify-markdown-editor'
import { mapActions } from 'vuex'

export default {
  components: { AddRoomModal, MyLoader, Editor },
  data() {
    return {
      tab: ref(''),
      event_data: null,
      reservationList: [],
      loading: true,
      loader: false,
      statusColor: {
        Оплачен: 'success',
        Частично: 'warning',
        Неоплачен: 'error',
        Переплата: 'error',
      },
      uuid_event: null,
      free_event_rooms: [],
      new_reservation_popup: false,
      headers: [
        {
          text: 'ID Заказа',
          value: 'id',
          align: 'start',
          width: '110',
        },
        { text: 'Пользователь', value: 'user' },
        { text: 'Номер', value: 'room' },
        { text: 'Сумма', value: 'pay_price' },
        { text: 'Оплачено', value: 'pay_sum' },
        { text: 'Остаток', value: 'pay_debt' },
        { text: 'Оплачено бонусами', value: 'bonus_pay' },
        { text: 'Тип оплаты', value: 'payment_method' },
        { text: 'Статус', value: 'pay_status', align: 'left' },
      ],
      headers_rooms: [{ text: 'Номер', value: 'name', align: 'start' }],
      status: {
        1: 'Оплачен',
        2: 'Частично',
        3: 'Неоплачен',
        4: 'Переплата',
        5: '–',
      },
      payment_method_type: {
        1: 'Картой',
        2: 'Наличными',
        3: 'Личный счет',
        4: 'Смешанная',
        5: '–',
      },
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiCurrencyRub,
        mdiBedKing,
        mdiCart,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiAccount,
        mdiAccountGroup,
        mdiEye,
        mdiSwapHorizontal,
        mdiLeadPencil,
        mdiContactlessPaymentCircle,
        mdiShieldAccount,
        mdiSale,
        mdiFileDocument,
      },
      event_rooms_list: Array,
      event_people_list: Array,
      new_event_room_id: Intl,
      reservation_room_id: Intl,
      event_people_list_by_days: Array,

      new_offer_popup: false,
      valid: true,
      trainer_name: '',
      trainer_nameRules: [v => !!v || 'Имя обязательное поле'],
      trainer_email: '',
      trainer_emailRules: [v => !!v || 'E-mail обязательное поле', v => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      agreed_functionality: '',
      agreed_functionalityRules: [v => !!v || 'Имя обязательное поле'],
      salary_description: '',
      salary_descriptionRules: [v => !!v || 'Имя обязательное поле'],
      offer_start_date: null,
      offer_end_date: null,

      offers_list: [],
      renderConfig: {
        // Mermaid config
        mermaid: {
          theme: 'dark',
        },
      },
    }
  },
  computed: {
    tabs() {
      return [
        { title: 'Бронирования', icon: mdiCart, id: 1 },
        { title: 'Расселение', icon: mdiBedKing, id: 2 },
        { title: 'Участники', icon: mdiAccountGroup, id: 3 },
        { title: 'Офферы', icon: mdiFileDocument, id: 4 },
        {
          title: 'Пакеты Мероприятия',
          icon: mdiAccountGroup,
          id: 5,
          to: 'packages',
        },
        {
          title: 'Обратная связь',
          icon: mdiAccountGroup,
          id: 6,
          to: `feedback/${this.uuid_event}`,
        },
      ]
    },
  },
  async created() {
    this.$watch(() => this.$route.params)
  },
  async mounted() {
    this.getEventDataById()
    this.getEventById()
    this.getTrainerOffer()
  },
  methods: {
    getEventDataById() {
      try {
        Api.getEventDataById(parseInt(this.$route.params.id)).then(event => {
          this.$set(this, 'event_data', event)
          this.$set(this, 'uuid_event', event.uuid)
          this.$set(this, 'offer_start_date', event.start_date)
          this.$set(this, 'offer_end_date', event.end_date)
        })
      } catch (error) {}
    },
    getEventById() {
      try {
        Api.getReservationListByEventId({
          event_id: parseInt(this.$route.params.id),
        }).then(event => {
          this.$set(this, 'reservationList', event)
          this.$set(this, 'loading', 'false')
        })
      } catch (error) {}
    },
    getRoomForEvents() {
      try {
        this.loader = true
        Api.getRoomForEvents({
          event_id: parseInt(this.$route.params.id),
        }).then(event => {
          this.$set(this, 'event_rooms_list', event)
          this.$set(this, 'loader', false)
        })
      } catch (error) {
        this.loader = false
      }
    },
    getEventPeopleList() {
      try {
        this.loader = true
        Api.getEventPeopleList({
          event_id: parseInt(this.$route.params.id),
        }).then(event => {
          this.$set(this, 'event_people_list', event)
          this.$set(this, 'loader', false)
        })
      } catch (error) {
        this.loader = false
      }
    },
    getEventPeopleByDays() {
      try {
        this.loader = true
        Api.getEventPeopleByDays({
          event_id: parseInt(this.$route.params.id),
        }).then(event => {
          this.$set(this, 'event_people_list_by_days', event)
          this.$set(this, 'loader', false)
        })
      } catch (error) {
        this.loader = false
      }
    },
    changeEventPeopleSportType(event_people) {
      try {
        this.loader = true
        Api.changeEventPeopleSportType({
          event_people_id: event_people.id,
          kind_of_sport: event_people.sport,
        }).then(event => {
          this.getEventPeopleList()
        })
      } catch (error) {
        this.loader = false
      }
    },
    ...mapActions(['addReservationData']),
    async AddReservation(event_room_id) {
      try {
        Api.AddReservation({
          event_room_id,
        }).then(event => {
          if (event.reservation_uuid) {
            this.addReservationData(event)
            this.$router.push({
              path: `/events/${this.$route.params.id}/new`,
              replace: true,
            })
          }
        })
      } catch (error) {
        this.loader = false
      }
    },
    async getTabsData(tab) {
      console.log(tab.to)
      if (tab.to) {
        this.$router.push({
          path: `/events/${this.$route.params.id}/${tab.to}`,
          replace: true,
        })
      }

      if (tab.id == 2) {
        this.event_rooms_list = []
        this.getRoomForEvents()
      }

      if (tab.id == 3) {
        this.event_people_list = []
        this.getEventPeopleList()
        this.getEventPeopleByDays()
      }
    },
    getRoomListByEventIdAndRoomType(reservation_data) {
      this.reservation_room_id = reservation_data.event_room_reservation_id
      try {
        Api.getRoomListByEventIdAndRoomType({
          event_id: parseInt(this.$route.params.id),
          event_room_id: reservation_data.room_id,
        }).then(event => {
          this.$set(this, 'free_event_rooms', event)
          this.$set(this, 'new_reservation_popup', true)
        })
      } catch (error) {}
    },
    changeRoom(suit_id) {
      this.new_event_room_id = suit_id
      this.new_reservation_popup = false

      Api.resettleAdmin({
        reservation_room_id: this.reservation_room_id,
        new_room_id: this.new_event_room_id,
      }).then(event => {
        this.getRoomForEvents()
      })
    },
    getRoomData(roomId) {
      window.open(`https://lk.xbadm.ru/admin/events_app/eventroom/${roomId}/change/`, '_blank')
    },
    synchEventsRacketcamp() {
      this.loader = true
      Api.synchEventsRacketcamp({
        event_id: parseInt(this.$route.params.id),
      }).then(event => {
        console.log(event)
        this.loader = false
      })
    },
    addBonusTransaction() {
      this.loader = true
      Api.addBonusTransaction({
        event_id: parseInt(this.$route.params.id),
      }).then(event => {
        console.log(event)
        this.loader = false
      })
    },
    addOffer() {
      if (this.valid) {
        this.loader = true
        Api.addTrainerOffer({
          event_id: parseInt(this.$route.params.id),
          agreed_functionality: this.agreed_functionality,
          salary_description: this.salary_description,
          trainer_name: this.trainer_name,
          trainer_email: this.trainer_email,
          start_date: this.start_date,
          end_date: this.end_date,
          days_amount: this.end_date - this.start_date,
        }).then(event => {
          this.loader = false
          this.reset()
          this.$router.push({
            path: `/events/${this.$route.params.id}/offer/${event.uuid}`,
            replace: true,
          })
        })
      }
    },
    openOffer() {},
    getTrainerOffer() {
      const params = new URLSearchParams([['event_id', parseInt(this.$route.params.id)]])
      Api.getTrainerOffer({ params }).then(event => {
        console.log(event)
        this.offers_list = event
      })
    },
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
      this.new_offer_popup = false
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    sumField(key) {
      // sum data in give key (property)
      return this.reservationList.reduce((a, b) => a + (b[key] || 0), 0)
    },
    sumField(key) {
      // sum data in give key (property)
      return this.reservationList.reduce((a, b) => a + (b[key] || 0), 0)
    },
  },
}
</script>
<style lang="scss" scoped>
.range_object {
  flex: 1;
  border-left: 1px solid rgb(186, 186, 186);
  text-align: center;
  font-size: 10px;
  padding: 10px 0;

  &:first-child {
    border-left: none;
  }
}

.people_in_room_block {
  margin-top: 3px;
  background-color: #6b36cb !important;
  color: #fff;
}

.reservation_wrap {
  padding: 10px 0;
}

.reservation_block {
  position: relative;
  min-height: 30px;
}

.reservation_people_wrap {
  margin-top: 10px;

  .reservation_wrap {
    padding: 0;
    margin-bottom: 5px;
  }
}

.reservation_block_item {
  position: relative;
  min-height: 25px;

  .reservation {
    position: absolute;
    background-color: #673bb8;
    color: #fff;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 13px;
  }
}

.reservation {
  position: absolute;
  background-color: #9155fd;
  color: #fff;
  border-radius: 20px;
  padding: 0px 10px;
  font-size: 13px;
}

.reservation_confirm {
  font-weight: bold;
}
</style>
