<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="align-center">
      <h1 class="mb-5">Клубный игрок</h1>
    </div>

    <div style="margin-bottom: 20px">
      <router-link :to="'/club_player/clients'">
        <span class="d-block font-weight-semibold">Список участников</span>
      </router-link>
    </div>

    <!-- Фильтр по статусу и дате -->
    <v-card class="filters mb-4">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-select v-model="selectedStatus" :items="statusOptions" label="Фильтр по статусу" clearable></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field v-model="dateFilter" label="Фильтр по дате" type="date" clearable></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <!-- Таблица -->
    <v-card>
      <v-data-table
        mobile-breakpoint="100"
        :headers="headers"
        :items="filteredGiftList"
        item-key="request_pk"
        :loading="loading"
        class="elevation-1"
        loading-text="Загрузка данных"
      >
        <template #[`item.request_pk`]="{ item }">
          <div class="d-flex flex-column">
            <span class="d-block font-weight-semibold">{{ item.request_pk }}</span>
          </div>
        </template>

        <template #[`item.action`]="{ item }">
          <v-btn
            v-if="item.request_status != 'COMPLETED'"
            small
            color="primary"
            @click="completeGiftRequest(item.request_pk)"
          >
            Выдать
          </v-btn>
          <v-btn
            v-if="item.request_status != 'COMPLETED'"
            small
            color="error"
            class="ml-2"
            @click="deleteGiftRequest(item.request_pk)"
          >
            Удалить
          </v-btn>

          <span v-if="item.request_status == 'COMPLETED'"> - </span>
        </template>

        <template #[`item.request_status`]="{ item }">
          <v-chip class="font-weight-medium" :color="statusColor[item.request_status]" small>
            {{ status[item.request_status] }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Api from '@/api/api'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: { MyLoader },
  data() {
    return {
      loading: false,
      loader: false,
      statusColor: {
        CREATED: 'info',
        PROCESSING: 'danger',
        COMPLETED: 'success',
      },
      errorMessages: '',
      gift_list: [],
      headers: [
        {
          text: 'ID',
          value: 'request_pk',
          align: 'start',
          width: '100',
        },
        { text: 'Подарок', value: 'gift_title' },
        { text: 'Пользователь', value: 'user_name' },
        { text: 'Дата', value: 'date' },
        { text: 'Статус', value: 'request_status' },
        { text: 'Действие', value: 'action' },
      ],
      status: {
        CREATED: 'создан',
        PROCESSING: 'в обработке',
        COMPLETED: 'выдан',
      },
      selectedStatus: null, // Выбранный статус для фильтрации
      dateFilter: '', // Поле для фильтрации по дате
      statusOptions: [
        { text: 'Создан', value: 'CREATED' },
        { text: 'Выдан', value: 'COMPLETED' },
      ],
    }
  },
  computed: {
    filteredGiftList() {
      return this.gift_list.filter(item => {
        const matchesStatus = !this.selectedStatus || item.request_status === this.selectedStatus

        // Проверка на корректность значения item.date
        const dateValue = item.date
        const isValidDate = dateValue && /^\d{2}\.\d{2}\.\d{4}$/.test(dateValue) // Проверка формата DD.MM.YYYY

        // Логгирование значений
        console.log(`Item Date: ${dateValue}, Valid: ${isValidDate}`)

        // Преобразование формата даты DD.MM.YYYY в YYYY-MM-DD для сравнения
        const formattedItemDate = isValidDate
          ? dateValue.split('.').reverse().join('-') // Преобразуем в формат YYYY-MM-DD
          : null

        console.log(`Formatted Item Date: ${formattedItemDate}`)

        // Преобразование dateFilter в формат YYYY-MM-DD
        const formattedFilterDate = this.dateFilter
          ? this.dateFilter.split('.').reverse().join('-') // Преобразуем в формат YYYY-MM-DD
          : null

        const matchesDate = !this.dateFilter || formattedItemDate === formattedFilterDate

        // Логгирование результата
        console.log(`Matches Date: ${matchesDate}, Filter Date: ${formattedFilterDate}`)

        return matchesStatus && matchesDate
      })
    },
  },
  async mounted() {
    this.giftRequests()
  },
  methods: {
    async giftRequests() {
      this.loading = true
      try {
        const event = await Api.giftRequests()
        this.gift_list = event
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    completeGiftRequest(gift_request_pk) {
      if (confirm('Вы уверены что хотите выдать подарок?')) {
        this.loading = true
        try {
          Api.completeGiftRequest({ gift_request_pk }).then(() => {
            this.loading = false
            this.giftRequests()
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    deleteGiftRequest(gift_request_pk) {
      if (confirm('Вы уверены что хотите удалить запрос на получение подарка?')) {
        this.loading = true
        try {
          Api.deleteGiftRequest({ gift_request_pk }).then(() => {
            this.loading = false
            this.giftRequests()
          })
          Api.deleteGiftRequest({
            gift_request_pk,
          }).then(event => {
            this.$set(this, 'loading', false)
            this.giftRequests()
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
  },
}
</script>

<style>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.search_calendar {
  margin-bottom: 20px;
  margin-top: 20px;
}

.mx-input {
  height: 48px;
}

.more_small {
}
</style>
