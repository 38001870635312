<template>
  <div>
    <v-dialog v-model="loader" hide-overlay persistent width="300">
      <v-card color="primary">
        <v-card-text class="pt-4">
          <v-progress-linear indeterminate color="white" class=""></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'MyLoader',
  props: {
    loader: { type: Boolean, required: false },
  },
  setup() {
    return {}
  },
  methods: {},
}
</script>
