<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span class="headline">Список отчётов</span>
      </v-card-title>

      <v-data-table :headers="headers" :items="reports" class="elevation-1" disable-pagination hide-default-footer>
        <template v-slot:item.action="{ item }">
          <v-btn small color="primary" @click="handleReportClick(item)"> Запустить отчёт </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- Модалка -->
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Настройки отчёта</span>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-row>
              <template v-for="field in currentReport?.fields || []">
                <!-- TEXT FIELD -->
                <v-col v-if="field.type === 'text'" :key="field.key" cols="12" sm="6">
                  <v-text-field v-model="fieldValues[field.key]" :label="field.label" outlined dense />
                </v-col>

                <!-- SELECT FIELD -->
                <v-col v-else-if="field.type === 'select'" :key="field.key" cols="12" sm="6">
                  <v-select
                    v-model="fieldValues[field.key]"
                    :label="field.label"
                    :items="field.options || []"
                    outlined
                    dense
                  />
                </v-col>

                <!-- DATE FIELD -->
                <v-col v-else-if="field.type === 'date'" :key="field.key" cols="12" sm="6">
                  <v-text-field
                    v-model="fieldValues[field.key]"
                    :label="field.label"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    @click="openDateDialog(field.key)"
                  />

                  <v-dialog v-model="activeDatePickerDialog" persistent width="290px" :retain-focus="false">
                    <v-card v-if="activeDatePicker">
                      <v-date-picker
                        v-model="pickerValues[activeDatePicker]"
                        no-title
                        scrollable
                        @input="confirmDate"
                      />
                    </v-card>
                  </v-dialog>
                </v-col>
              </template>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog"> Отмена </v-btn>
          <v-btn color="primary" @click="confirmReport"> Запустить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from '@/api/api'

export default {
  name: 'ReportsPage',
  data() {
    return {
      headers: [
        { text: 'Название отчета', value: 'name' },
        { text: 'Действие', value: 'action', sortable: false },
      ],
      reports: [
        {
          name: 'Финансовый отчет филиала "Залы Khimki BC"',
          action: 'getCompanyReport',
          baseParams: {},
          fields: [
            { key: 'date_from', label: 'Дата начала', type: 'date' },
            { key: 'date_to', label: 'Дата окончания', type: 'date' },
          ],
        },
      ],
      dialog: false,
      currentReport: null,
      fieldValues: {},
      pickerValues: {}, // Для хранения raw дат
      activeDatePicker: null,
      activeDatePickerDialog: false,
    }
  },
  methods: {
    handleReportClick(report) {
      this.currentReport = report
      this.fieldValues = {}
      this.pickerValues = {}

      report.fields?.forEach(field => {
        this.fieldValues[field.key] = null
        if (field.type === 'date') {
          this.pickerValues[field.key] = null
        }
      })

      this.dialog = true
    },
    openDateDialog(key) {
      this.activeDatePicker = key
      this.activeDatePickerDialog = true
    },

    formatDate(date) {
      if (!date) return null
      const d = new Date(date)
      const yyyy = d.getFullYear()
      const mm = String(d.getMonth() + 1).padStart(2, '0')
      const dd = String(d.getDate()).padStart(2, '0')
      return `${yyyy}-${mm}-${dd}`
    },

    confirmDate() {
      const key = this.activeDatePicker
      const date = this.pickerValues[key]
      this.fieldValues[key] = this.formatDate(date)
      this.activeDatePickerDialog = false
      this.activeDatePicker = null
    },

    closeDateDialog() {
      this.activeDatePicker = null
      this.activeDatePickerDialog = false
    },

    closeDialog() {
      this.dialog = false
      this.currentReport = null
    },

    async confirmReport() {
      const params = {
        ...this.currentReport.baseParams,
        ...this.fieldValues,
      }
      this.dialog = false
      await this.runReport(this.currentReport, params)
    },

    async runReport(report, overrideParams = null) {
      try {
        const actionFn = this[report.action]
        const params = overrideParams || report.baseParams || {}
        const result = await actionFn(params)

        const message = result?.message || 'Отчёт успешно получен'

        this.$toast?.success?.(message) || alert(message)
      } catch (error) {
        const message = error?.response?.data?.message || 'Ошибка при получении отчёта'
        console.error(error)
        this.$toast?.error?.(message) || alert(message)
      }
    },

    async getCompanyReport(params) {
      return await Api.getCompanyReport(params)
    },
  },
}
</script>
