<template>
  <div>
    <div class="align-center"></div>

    <div v-if="feedback_list">
      <p class="text-2xl mb-6">
        Обратная связь
      </p>

      <div>
        <v-checkbox
          v-model="filterWithAnswers"
          label="Только с ответами"
          @change="getFeedbackList"
        ></v-checkbox>
      </div>

      <div class="star-filter">
        <span
          v-for="star in 5"
          :key="star"
          class="star"
          :class="{ filled: star <= selectedRating }"
          @click="filterByRating(star)"
        >★</span>
      </div>

      <v-card>
        <v-data-table
          :headers="headers"
          :items="feedback_list"
          :loading="loading"
          :server-items-length="30"
          loading-text="Загрузка данных"
          class="elevation-1"
          :hide-default-footer="true"
          :item-class="rowClass"
        >
          <template #[`item.id`]="{ item }">
            <router-link :to="'/feedback/' + item.uuid">
              <span>{{ item.id }}</span>
            </router-link>
          </template>

          <template v-slot:[`item.training_time`]="{ item }">
            {{ item.training_time | formatDate }}
          </template>

          <template v-slot:[`item.answered_at`]="{ item }">
            {{ item.answered_at | formatDate }}
          </template>

          <template v-slot:[`item.rating`]="{ item }">
            <div>
              <span
                v-for="star in 5"
                :key="star"
                class="star"
                :class="{ filled: star <= item.rating }"
              >★</span>
            </div>
          </template>
        </v-data-table>

        <v-pagination
          v-model="current_page"
          :length="total_page"
          @input="getFeedbackList"
        ></v-pagination>
      </v-card>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api'

export default {
  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
    formatDate(value) {
      const date = new Date(value)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const hour = date.getHours()
      const minute = date.getMinutes()
      return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year} ${hour}:${minute === 0 ? '00' : minute}`
    },
  },
  data() {
    return {
      loading: false,
      totalFeedback: null,
      total_page: null,
      current_page: 1,
      filterWithAnswers: true,
      selectedRating: 0,
      headers: [
        { text: 'Номер', value: 'id', align: 'start' },
        { text: 'Клиент', value: 'client_name' },
        { text: 'Телефон', value: 'client_phone' },
        { text: 'Тренер', value: 'staff_name' },
        { text: 'Дата тренировки', value: 'training_time' },
        { text: 'Дата ответа', value: 'answered_at' },
        { text: 'Рейтинг', value: 'rating' },
      ],
      options: {},
      errorMessages: '',
      feedback_list: [],
      status: {
        true: 'выдана',
        false: 'не выдана',
      },
      statusColor: {
        false: 'info',
        true: 'success',
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getFeedbackList()
      },
      deep: true,
    },
  },

  async created() {
    this.$watch(() => this.$route.params)
  },

  async mounted() {
    this.getFeedbackList()
  },

  methods: {
    getFeedbackList() {
      this.loading = true
      const params = new URLSearchParams([['page', this.current_page]])
      if (this.filterWithAnswers) {
        params.append('is_answer', '1')
      }
      if (this.selectedRating > 0) {
        params.append('rating', this.selectedRating)
      }
      try {
        Api.getUsersFeedback({ params }).then(event => {
          this.$set(this, 'loading', false)
          this.$set(this, 'feedback_list', event.results)
          this.$set(this, 'totalFeedback', event.results.length)
          this.$set(this, 'current_page', event.current_page)
          this.$set(this, 'total_page', event.num_pages)
        })
      } catch (error) {
        console.log(error)
      }
    },

    reset() {
      this.$refs.form.reset()
    },

    updateSelected(data) {
      this.selected_user = data
    },

    filterByRating(rating) {
      this.selectedRating = rating
      this.getFeedbackList()
    },

    rowClass(item) {
      return item.read ? 'read' : 'unread'
    },
  },
}
</script>

<style>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.unread {
  font-weight: bold;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.mx-input {
  height: 48px;
}

.btn_list {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
}

.star-filter {
  display: flex;
  margin-bottom: 15px;
}

.star {
  font-size: 18px;
  cursor: pointer;
  color: #ccc;
}

.star.filled {
  color: #2f65ae;
}
</style>
