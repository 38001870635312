<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      isDrawerOpen: 1,
    }
  },
  async created() {
    this.isAuthenticated = !!this.$store?.state?.auth?.user
  },
}
</script>

<style>
</style>
