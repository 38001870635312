<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import LayoutShort from '@/layouts/Short.vue'
import { useRouter } from '@/utils'
import { computed } from 'vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    LayoutShort,
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'short') return 'layout-short'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
  created() {},
}
</script>
