<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="">
      <div
        v-if="event_data"
        class="app-content-container boxed-container pa-6"
      >
        <h1>{{ event_data.name }}</h1>
        <h2>Обратная связь для спортсменов</h2>
      </div>
      <div class="mx-6 mb-10">
        <v-btn
          color="success"
          @click="sendFeedBackToEmail"
        >
          Отправить на почту
        </v-btn>
      </div>
    </div>

    <v-card>
      <v-card-text>
        <v-select
          v-model="filter"
          class="mb-4"
          :items="[
            { text: `Заполненные (${countTrue})`, value: true },
            { text: `Пустые (${countFalse})`, value: false },
            { text: `Черновики (${countDraft})`, value: 'draft' },
            { text: `Все (${feedback_event_lists.length})`, value: null },
          ]"
          label="Фильтр по статусу"
          dense
          outlined
          hide-details
        />
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="filteredFeedbackList"
        :items-per-page="1000"
        item-key="id"
        :loading="loading"
        class="elevation-1"
        loading-text="Загрузка данных"
      >
        <template #[`item.has_feedback`]="{ item }">
          <span v-if="item.feedback?.draft">Черновик</span>
          <span
            v-else
          >{{ status[item.has_feedback] }}
            <v-icon
              v-if="item.has_feedback"
              color="success"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>
          </span>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn
            x-small
            color="success"
            @click="openFeedbackModal(item)"
          >
            {{ item.has_feedback ? 'Редактировать' : 'Заполнить' }}
          </v-btn>
        </template>

        <template #[`item.checked`]="{ item }">
          <v-icon
            v-if="item.feedback?.checked"
            color="success"
          >
            {{ icons.mdiCheckCircle }}
          </v-icon>
          <v-icon
            v-else
            color="grey lighten-1"
          >
            {{ icons.mdiCheckboxBlankCircleOutline }}
          </v-icon>
        </template>

        <template #[`item.send_status`]="{ item }">
          <v-icon
            v-if="item.feedback?.send_status"
            color="success"
          >
            {{ icons.mdiCheckCircle }}
          </v-icon>
          <v-icon
            v-else
            color="grey lighten-1"
          >
            {{ icons.mdiCheckboxBlankCircleOutline }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="feedback_modal"
      max-width="800"
    >
      <v-card>
        <div class="d-flex justify-space-between">
          <div>
            <v-card-title class="mb-2">
              Спортсмен: {{ selected_feedback?.full_name }}
            </v-card-title>
            <v-card-subtitle>
              {{ selected_feedback?.has_feedback ? 'Редактировать обратную связь' : 'Добавить обратную связь' }}
            </v-card-subtitle>
          </div>

          <div class="text-rigth mt-4 mr-5">
            <v-btn
              small
              color="primary"
              outlined
              @click="preview_mode = !preview_mode"
            >
              {{ preview_mode ? 'Вернуться к редактированию' : 'Предпросмотр' }}
            </v-btn>
          </div>
        </div>

        <template v-if="!preview_mode">
          <v-card-text>
            <div><b>Над чем работали на сборах</b></div>
            <p v-if="event_data">
            </p><div v-html="event_data.feedback_text_event"></div>
            </p>

            <transition
              name="fade"
              mode="out-in"
            >
              <v-alert
                v-if="selected_feedback?.feedback?.draft"
                type="warning"
                dense
                class="mb-5"
                text
              >
                Это <b>черновик</b> отзыва. Заполните оба поля, чтобы завершить.
              </v-alert>
            </transition>

            <transition
              name="fade"
              mode="out-in"
            >
              <v-alert
                v-if="selected_feedback?.feedback?.checked"
                type="success"
                dense
                class="mb-5"
                text
              >
                Отзыв <b>проверен руководителем</b>
              </v-alert>
            </transition>

            <v-form ref="feedbackForm">
              <div class="mb-4">
                <label><b>Что получилось хорошо</b></label>
                <VueEditor
                  ref="goodEditor"
                  v-model="form.goodwork"
                  :editor-toolbar="editorSettings.modules.toolbar"
                />
              </div>

              <div class="mb-4">
                <label><b>Рекомендации</b></label>
                <VueEditor
                  ref="recommendationEditor"
                  v-model="form.recommendation"
                  :editor-toolbar="editorSettings.modules.toolbar"
                />
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <template>
              <v-icon
                v-if="isAutoSaving"
                color="primary"
                small
                class="mr-1"
              >
                {{ icons.mdiClockOutline }}
              </v-icon>
              <v-icon
                v-else-if="wasAutoSaved"
                color="green"
                small
                class="mr-1"
              >
                {{ icons.mdiCheckCircle }}
              </v-icon>
              <span
                v-if="isAutoSaving"
                style="font-size: 14px"
              >Сохраняется...</span>
              <span
                v-else-if="wasAutoSaved"
                style="font-size: 14px"
              >Сохранено</span>
            </template>
            <v-spacer></v-spacer>
            <v-btn
              v-if="
                isAuthenticated &&
                selected_feedback?.feedback?.id &&
                  !selected_feedback.feedback.checked &&
                  !selected_feedback.feedback.draft
              "
              color="primary"
              text
              @click="markAsChecked"
            >
              Отметить как проверено
            </v-btn>

            <v-btn
              text
              @click="closeFeedbackModal"
            >
              Отмена
            </v-btn>
            <v-btn
              color="primary"
              @click="submitFeedback"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </template>

        <div
          v-else
          class="email-preview"
        >
          <h3>Здравствуйте, {{ selected_feedback?.full_name }}</h3>
          <p>
            Ниже обратная связь по сборам
            <b>{{ event_data.name }}</b>:
          </p>
          <h4 class="mt-5">
            Над чем работали
          </h4>
          <p
            v-if="event_data"
            style="white-space: pre-line"
          >
            {{ event_data.feedback_text_event }}
          </p>

          <h4 class="mt-5">
            Что получилось хорошо
          </h4>
          <div
            class="email-preview_block"
            v-html="form.goodwork"
          ></div>

          <h4 class="mt-4">
            Рекомендации
          </h4>
          <div
            class="email-preview_block"
            v-html="form.recommendation"
          ></div>
        </div>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="2500"
      color="success"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import Api from '@/api/api'
import MyLoader from '@/components/MyLoader.vue'
import { mdiCheckCircle, mdiCheckboxBlankCircleOutline, mdiClockOutline } from '@mdi/js'
import { ref } from 'vue'
import { VueEditor } from 'vue2-editor'

export default {
  components: { MyLoader, VueEditor },
  data() {
    return {
      tab: ref(''),
      event_data: null,
      feedback_event_lists: [],
      loading: true,
      loader: false,
      form: {
        goodwork: '',
        recommendation: '',
      },
      editorSettings: {
        modules: {
          toolbar: [['bold', 'italic'], [{ list: 'ordered' }, { list: 'bullet' }], ['link'], ['clean']],
          placeholder: 'Введите текст...',
          sanitize: true,
        },
      },
      feedback_modal: false,
      selected_feedback: null,
      snackbar: false,
      snackbar_text: '',
      status: {
        false: 'Пусто',
        true: 'Заполнено',
        draft: 'Черновик',
      },
      filter: false,
      icons: {
        mdiCheckCircle,
        mdiCheckboxBlankCircleOutline,
        mdiClockOutline,
      },
      autoSaveTimeout: null,
      isAutoSaving: false,
      wasAutoSaved: false,
      preview_mode: false,
      pasteListenerSet: false,
      pasteListenerSetRecommendation: false,
      pollingInterval: null,
    }
  },
  computed: {
    filteredFeedbackList() {
      if (this.filter === null) return this.feedback_event_lists

      if (this.filter === 'draft') {
        return this.feedback_event_lists.filter(item => item.feedback?.draft)
      }

      return this.feedback_event_lists.filter(item => item.has_feedback === this.filter && !item.feedback?.draft)
    },
    countTrue() {
      return this.feedback_event_lists.filter(i => i.has_feedback && !i.feedback?.draft).length
    },
    countFalse() {
      return this.feedback_event_lists.filter(i => !i.has_feedback).length
    },
    countDraft() {
      return this.feedback_event_lists.filter(i => i.feedback?.draft).length
    },
    headers() {
      const base = [
        { text: 'Спортсмен', value: 'full_name' },
        { text: 'Статус', value: 'has_feedback' },
        { text: 'Управление', value: 'actions' },
      ]

      if (this.isAuthenticated) {
        base.splice(2, 0, { text: 'Проверено', value: 'checked' })
      }

      if (this.isAuthenticated) {
        base.splice(3, 0, { text: 'Отправлено', value: 'send_status' })
      }

      return base
    },
  },

  // watch: {
  //   'form.goodwork': 'triggerAutoSave',
  //   'form.recommendation': 'triggerAutoSave',
  // },
  async created() {
    this.$watch(() => this.$route.params)
    this.isAuthenticated = !!this.$store?.state?.auth?.user
  },

  // beforeUnmount() {
  //   if (this.pollingInterval) {
  //     clearInterval(this.pollingInterval)
  //   }
  // },
  async mounted() {
    this.getEventDataById()
    await this.getEventPeopleWithFeedback()

    // this.pollingInterval = setInterval(() => {
    //   if (!this.feedback_modal) {
    //     this.getEventPeopleWithFeedback()
    //   }
    // }, 15000)
  },
  methods: {
    getEventDataById() {
      try {
        Api.getEventDataById(parseInt(this.$route.params.id)).then(event => {
          this.$set(this, 'event_data', event)
        })
      } catch (error) {}
    },
    async getEventPeopleWithFeedback() {
      try {
        const data = await Api.getEventPeopleWithFeedback(this.$route.params.uuid)
        this.$set(this, 'feedback_event_lists', data)
        this.$set(this, 'loading', false)
      } catch (error) {
        console.error('Ошибка при получении списка участников:', error)
      }
    },
    openFeedbackModal(item) {
      this.selected_feedback = item
      this.form.goodwork = item.feedback?.goodwork || ''
      this.form.recommendation = item.feedback?.recommendation || ''
      this.feedback_modal = true

      this.$nextTick(() => {
        setTimeout(() => {
          const { goodEditor } = this.$refs
          const recEditor = this.$refs.recommendationEditor

          if (goodEditor?.quill && !this.pasteListenerSet) {
            this.setupPasteHandler(goodEditor.quill)
          }

          if (recEditor?.quill && !this.pasteListenerSetRecommendation) {
            this.setupPasteHandler(recEditor.quill)
            this.pasteListenerSetRecommendation = true
          }
        }, 100)
      })
    },
    closeFeedbackModal() {
      this.feedback_modal = false
      this.selected_feedback = null
      this.form.goodwork = ''
      this.form.recommendation = ''
    },

    async submitFeedback() {
      const payload = {
        event: this.event_data.id,
        player: this.selected_feedback.id,
        trainer: this.event_data.trainer || 'Не указан',
        goodwork: this.form.goodwork,
        recommendation: this.form.recommendation,
        checked: false,
        send_status: 0,
      }

      try {
        if (this.selected_feedback.has_feedback && this.selected_feedback.feedback?.id) {
          await Api.patchFeedback(this.selected_feedback.feedback.id, payload)
          this.snackbar_text = 'Отзыв сохранён'
        } else {
          await Api.createFeedback(payload)
          this.snackbar_text = 'Отзыв создан'
        }

        if (!this.form.goodwork || !this.form.recommendation) {
          this.snackbar_text = 'Сохранено как черновик (не все поля заполнены)'
        }

        this.snackbar = true
        this.closeFeedbackModal()
        this.getEventPeopleWithFeedback()
      } catch (error) {
        console.error('Ошибка при сохранении обратной связи:', error)
        this.snackbar_text = 'Ошибка при сохранении'
        this.snackbar = true
      }
    },
    async markAsChecked() {
      try {
        const { id } = this.selected_feedback.feedback
        await Api.patchFeedback(id, { checked: true })

        this.snackbar_text = 'Отметка "Проверено" сохранена'
        this.snackbar = true

        this.closeFeedbackModal()
        this.getEventPeopleWithFeedback()
      } catch (error) {
        console.error('Ошибка при отметке "проверено":', error)
        this.snackbar_text = 'Ошибка при сохранении отметки'
        this.snackbar = true
      }
    },
    async sendFeedBackToEmail() {
      this.loader = true
      try {
        if (this.countFalse != 0) {
          alert('Перед отправкой убелитесь что проверили все отзывы')
        } else {
          await Api.sendfeedBackEmail({ event_uuid: this.$route.params.uuid }).then(event => {
            this.getEventPeopleWithFeedback()
            this.loader = false
            this.snackbar_text = `Успешно отправлено писем: ${event.sent_count}`
            this.snackbar = true
          })
        }
      } catch (error) {
        this.loader = false
      }
    },
    triggerAutoSave() {
      if (this.autoSaveTimeout) {
        clearTimeout(this.autoSaveTimeout)
      }

      // Не сохраняем, если модалка закрыта
      if (!this.feedback_modal) return

      this.autoSaveTimeout = setTimeout(() => {
        this.autoSaveDraft()
      }, 2000) // 2 сек задержка
    },
    async autoSaveDraft() {
      if (!this.selected_feedback) return

      const payload = {
        event: this.event_data.id,
        player: this.selected_feedback.id,
        trainer: this.event_data.trainer || 'Не указан',
        goodwork: this.form.goodwork,
        recommendation: this.form.recommendation,
        checked: false,
        send_status: 0,
      }

      this.isAutoSaving = true
      this.wasAutoSaved = false

      try {
        if (this.selected_feedback.has_feedback && this.selected_feedback.feedback?.id) {
          await Api.patchFeedback(this.selected_feedback.feedback.id, payload)
        } else {
          await Api.createFeedback(payload)
        }

        this.wasAutoSaved = true

        setTimeout(() => {
          this.wasAutoSaved = false
        }, 1500)

        await this.getEventPeopleWithFeedback()

        // 🧠 Обновляем selected_feedback из нового списка
        const updated = this.feedback_event_lists.find(i => i.id === this.selected_feedback.id)
        if (updated) {
          this.$set(this, 'selected_feedback', JSON.parse(JSON.stringify(updated)))
        }
      } catch (e) {
        console.error('Ошибка автосохранения черновика', e)
      } finally {
        this.isAutoSaving = false
      }
    },
    sanitizeLink(url) {
      const clean = url.trim()
      const blacklist = ['javascript:', 'data:', 'vbscript:']

      for (const bad of blacklist) {
        if (clean.toLowerCase().startsWith(bad)) return null
      }

      if (!/^https?:\/\//i.test(clean)) {
        return `https://${clean}`
      }

      return clean
    },
    onEditorCreated(editor) {
      console.log('📝 Editor created:', editor)

      const { quill } = editor

      // Лог при вставке
      quill.root.addEventListener('paste', event => {
        console.log('📋 Paste event detected')

        const clipboardData = event.clipboardData || window.clipboardData
        const pastedText = clipboardData.getData('text/plain')

        console.log('🧾 Pasted text:', pastedText)

        const urlPattern = /^(https?:\/\/[^\s]+)/

        if (urlPattern.test(pastedText)) {
          event.preventDefault()
          const sanitized = this.sanitizeLink(pastedText)

          if (sanitized) {
            const range = quill.getSelection(true)
            quill.insertText(range.index, sanitized, 'link', sanitized)
            quill.setSelection(range.index + sanitized.length)
            console.log('✅ Inserted sanitized link:', sanitized)
          } else {
            console.warn('⛔️ Заблокирована небезопасная ссылка')
          }
        }
      })
    },
    setupPasteHandler(quill) {
      quill.root.addEventListener('paste', event => {
        const clipboardData = event.clipboardData || window.clipboardData
        const pastedText = clipboardData.getData('text/plain')

        const urlPattern = /^(https?:\/\/[^\s]+)/

        event.preventDefault()

        const sanitized = this.sanitizeLink(pastedText)
        const range = quill.getSelection(true)

        // Если это ссылка — вставим как ссылку
        if (urlPattern.test(pastedText) && sanitized) {
          quill.insertText(range.index, sanitized, 'link', sanitized)
        } else {
          // Очищаем и вставляем как обычный текст (без форматирования)
          quill.insertText(range.index, pastedText)
        }

        quill.setSelection(range.index + pastedText.length)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

::v-deep .ql-editor {
  font-size: 14px;
  line-height: 1.4;
}

.email-preview {
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fafafa;
  h3,
  h4 {
    margin-bottom: 4px;
  }
}

::v-deep(.email-preview_block p) {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
</style>
