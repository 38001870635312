<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-slide-x-transition>
          <a aria-label="" class="logo" tabindex="0" href="/">
            <v-img width="170" :src="require('@/assets/images/khimki_bc_group.svg')"></v-img>
          </a>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list v-if="$store.state.auth.user" expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'events')"
        title="Сборы"
        :to="{ name: 'Сборы' }"
        :icon="icons.mdiCalendarBlankOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'clients')"
        title="Клиенты"
        :to="{ name: 'Клиенты' }"
        :icon="icons.mdiAccount"
      ></nav-menu-link>
      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'bonuspoints')"
        title="Бонусы"
        :to="{ name: 'Бонусы' }"
        :icon="icons.mdiStarFourPointsOutline"
      ></nav-menu-link>

      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'events')"
        title="Купоны"
        :to="{ name: 'Купоны' }"
        :icon="icons.mdiTicketPercent"
      ></nav-menu-link>

      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'certs')"
        title="Сертификаты"
        :to="{ name: 'Сертификаты' }"
        :icon="icons.mdiFileCertificate"
      ></nav-menu-link>

      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'club_player')"
        title="Клубный игрок"
        :to="{ name: 'Клубный игрок' }"
        :icon="icons.mdiGift"
      ></nav-menu-link>

      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'promocodes')"
        title="Промокоды"
        :to="{ name: 'Промокоды' }"
        :icon="icons.mdiTicketPercent"
      ></nav-menu-link>

      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'tax_deduction')"
        title="Налоговый вычет"
        :to="{ name: 'Налоговый вычет' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link>

      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'acquiring')"
        title="Эквайринг"
        :to="{ name: 'Эквайринг' }"
        :icon="icons.mdiCreditCardOutline"
      ></nav-menu-link>

      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'sales')"
        title="Акции"
        :to="{ name: 'Акции' }"
        :icon="icons.mdiStarFourPointsOutline"
      ></nav-menu-link>

      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'feedback')"
        title="Обратная связь"
        :to="{ name: 'Обратная связь' }"
        :icon="icons.mdiCommentQuote"
      ></nav-menu-link>

      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'qrcode_lockers')"
        title="Шкафчики ВДНХ"
        :to="{ name: 'Шкафчики ВДНХ' }"
        :icon="icons.mdiTable"
      ></nav-menu-link>

      <nav-menu-link
        v-if="$store.state.auth.user.permissions.find(({ project }) => project === 'reports')"
        title="Отчеты"
        :to="{ name: 'Отчеты' }"
        :icon="icons.mdiMicrosoftExcel"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccount,
  mdiAccountCogOutline,
  mdiAlphaTBoxOutline,
  mdiCalendarBlankOutline,
  mdiCommentQuote,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiFileCertificate,
  mdiFileOutline,
  mdiFormSelect,
  mdiGift,
  mdiHomeOutline,
  mdiMap,
  mdiMicrosoftExcel,
  mdiStarFourPointsOutline,
  mdiTable,
  mdiTicketPercent,
} from '@mdi/js'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiCalendarBlankOutline,
        mdiMap,
        mdiAccount,
        mdiStarFourPointsOutline,
        mdiTicketPercent,
        mdiFileCertificate,
        mdiGift,
        mdiCommentQuote,
        mdiMicrosoftExcel,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
