<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="align-center"></div>

    <div v-if="promocodes_list">
      <p class="text-2xl mb-6">
        Список промокодов
      </p>

      <div>
        <ul class="btn_list">
          <li>
            <v-btn
              color="primary"
              @click="promocode_popup = true"
            >
              Добавить промокод
            </v-btn>
          </li>
        </ul>
      </div>

      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск промокода"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="promocodes_list"
          :search="search"
          :loading="loading"
          loading-text="Загрузка данных"
          class="elevation-1"
          :sort-by="'id'"
          :sort-desc="true"
        >
          <template #[`item.name`]="{ item }">
            <div class="d-flex align-center">
              <span class="font-weight-semibold">{{ item.name }}</span>
              <v-btn
                x-small
                class="copy-btn"
                color="primary"
                @click="copyToClipboard(item.url)"
              >
                Скопировать
              </v-btn>
            </div>
          </template>

          <template #[`item.users`]="{ item }">
            <p
              v-for="(dpt, index) in item.users"
              :key="index"
              class="mt-2"
            >
              {{ dpt.email }}
            </p>
          </template>

          <template #[`item.event_name`]="{ item }">
            <a
              :href="item.url"
              target="_blank"
              class="font-weight-semibold"
            >{{ item.event_name }}</a>
          </template>

          <template #[`item.expired_date`]="{ item }">
            <span>{{ item.expired_date | formatDate }}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <v-dialog
      v-model="promocode_popup"
      transition=""
      max-width="600"
      persistent
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          Создать промокод
        </v-toolbar>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <autocomplete @updateParent="updateSelected"></autocomplete>

            <v-text-field
              v-model="toUp"
              :rules="[v => !!v || 'Обязательное поле']"
              class="toUp"
              label="Промокод"
              required
              @keyup="uppercase"
            ></v-text-field>

            <div>
              <v-select
                v-model="promo_event"
                :items="events_list"
                item-text="title"
                item-value="value"
                label="Название сборов"
                :rules="[v => !!v || 'Обязательное поле']"
              ></v-select>
            </div>

            <v-text-field
              v-model.number="promocode_name"
              type="number"
              :rules="nameRules"
              label="Скидка"
              required
              hide-spin-buttons
            ></v-text-field>

            <template>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    handler
                    label="Срок"
                    v-bind="attrs"
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="date"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </template>
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="createPromocodes()"
          >
            Создать промокод
          </v-btn>
          <v-btn
            color="error"
            @click="
              promocode_popup = false
              reset()
            "
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'
import Autocomplete from '@/components/Autocomplete.vue'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: { MyLoader, Autocomplete },

  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
    formatDate(value) {
      const date = new Date(value)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
    },
  },

  data() {
    return {
      errorMessages: '',
      menu1: false,
      active_project: [],
      promocodes_list: [],
      events_list: [],
      search: null,
      promocode_popup: false,
      fee_filter: false,
      toUp: '',
      promo_event: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      dateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ),
    }
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  async created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {},
    )
  },

  setup() {
    const loading = false
    const loader = false
    const promocode_name = ''
    const selected_user = ''
    const statusColor = {
      CREATED: 'info',
      PROCESSING: 'danger',
      COMPLETED: 'success',
    }

    return {
      headers: [
        {
          text: 'ID', value: 'id', align: 'start', width: '65',
        },
        { text: 'Промокод', value: 'name', width: '65' },
        { text: 'Скидка', value: 'discount', width: '100' },
        { text: 'Название', value: 'event_name', width: '250' },
        { text: 'Клиент', value: 'users', width: '100' },
        { text: 'Срок', value: 'expired_date', width: '125' },
        { text: 'Использованно', value: 'used' },
      ],

      valid: '',
      statusColor,
      selected_user,

      promocode_name,
      nameRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v > 9) || 'Минимальная скидка 10%',
        v => (v && v < 51) || 'Максимальная скидка 50%',
      ],
    }
  },

  async mounted() {
    this.getPromocodes()
    this.getAllEvents()
  },

  methods: {
    copyToClipboard(url) {
      const el = document.createElement('textarea')
      el.value = url
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      alert(`Ссылка скопирована: ${url}`)
    },

    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    parseDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    getPromocodes() {
      this.loading = true
      try {
        Api.getPromocodes().then(
          (event => {
            this.$set(this, 'loading', false)
            this.$set(this, 'promocodes_list', event)
            this.promocodes_list = event
          }),
        )
      } catch (error) {
        console.log(error)
      }
    },

    getAllEvents() {
      this.loader = true
      try {
        Api.getAllEvents().then(
          (event => {
            event.forEach(item => {
              this.events_list.push({ title: item.title, value: item.id })
            })
            this.$set(this, 'loader', false)
          }),
        )
      } catch (error) {
        console.log(error)
      }
    },

    async createPromocodes() {
      this.loading = true
      if (this.$refs.form.validate()) {
        Api.createPromocodes({
          name: this.toUp,
          discount: parseInt(this.promocode_name),
          user_id: this.selected_user.id,
          event_id: this.promo_event,
          expired_date: this.dateFormatted,
        })
          .then(
            (event => {
              this.getPromocodes()
              this.reset()
              this.promocode_popup = false
            }),
          )
          .catch(error => {
            if (error.response.status === 400) {
              alert('Ошибка 400 (неверный запрос)')
              this.$store.dispatch('clearReservationData')
              this.$router.push({
                path: `/promocodes/${this.$route.params.id}`,
                replace: true,
              })
            } else {
              console.log('no valid')
            }
          })
      }
    },

    uppercase() {
      this.toUp = this.toUp.toUpperCase()
    },

    reset() {
      this.$refs.form.reset()
    },

    updateSelected(data) {
      this.selected_user = data
    },
  },
}
</script>

<style scoped>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.search_calendar {
  margin-bottom: 20px;
  margin-top: 20px;
}

.mx-input {
  height: 48px;
}

.btn_list {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
}

.copy-btn {
  font-size: 10px;
  padding: 2px 4px;
  margin-left: 10px;
}
</style>
